<template>
  <v-card :loading="isLoading">
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="RoboSAY POS İşlemleri"
            icon="mdi-credit-card"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('see-cc-payment')"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            @click:edit="handleEditClick"
            v-bind="titleBarAttrs"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'activities.cc-payments.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item, value }">
          <router-link
            v-if="can('see-house-income')"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.house_name="{ item, value }">
          <router-link
            v-if="value && can('see-house-income')"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_success="{ item, value }">
          <rs-table-cell-boolean
            :value="value"
            v-if="item.success_at"
            :title="new Date(item.success_at).toLocaleString()"
          />
          <rs-table-cell-boolean
            :value="value"
            v-else-if="item.failed_at"
            :title="new Date(item.failed_at).toLocaleString()"
          />
          <rs-table-cell-boolean :value="value" v-else />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_user_card="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_subscription="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_manually_verified="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_added_to_cluster="{ value }">
          <rs-table-cell-boolean :value="value" false-color="red" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_fail_checked="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount_for_cluster="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.commission="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster_id="{ item }">
          {{ item.cluster }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.id_text="{ item }">ROBO-{{ item.id }}</template>
      </v-data-table>
    </v-card-text>
    <!-- <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text> -->
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import { isPage } from "../../../mixins";

export default {
  name: "CCPaymentList",
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId: {
      handler() {
        this.loadList();
      },
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);

    if (this.$route.query.cluster_id) {
      this.updateClusterId(this.$route.query.cluster_id);
    }
  },
  data() {
    return {
      pageMeta: {
        title: "POS İşlemleri",
      },
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      titleBarAttrs: {
        exportUrl: "cc-payments",
        exportParams: this.getParams,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          hide: () => !this.can("see-cc-payment"),
          width: "30px",
          align: "center",
        },
        {
          text: "TYA",
          value: "cluster_id",
          searchable: "cluster",
          multiple: true,
          hide: () => this.clusterId,
        },
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
        },
        {
          text: "BB",
          value: "house_name",
          searchable: "text",
          width: "100px",
        },
        {
          text: "Tarih",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "TYA Tutar",
          value: "amount_for_cluster",
          searchable: "number",
          align: "end",
        },
        {
          text: "Sakin Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Hizmet Bedeli",
          value: "commission",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.status"),
          value: "is_success",
          searchable: "successFail",
          align: "center",
          width: "50px",
        },
        {
          text: "Kayıtlı Kart",
          value: "is_user_card",
          searchable: "yesNo",
          align: "center",
          width: "50px",
        },
        {
          text: "Otomatik",
          value: "is_subscription",
          searchable: "yesNo",
          align: "center",
          width: "50px",
        },
        {
          text: "Taksit",
          value: "installments",
          searchable: "number",
          align: "end",
          width: "50px",
        },
        {
          text: "ID",
          value: "id_text",
          searchable: "text",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "Elle Kontrol",
          value: "is_manually_verified",
          searchable: "yesNo",
          align: "center",
          width: "50px",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "TYA İşlenme",
          value: "is_added_to_cluster",
          searchable: "yesNo",
          align: "center",
          width: "50px",
          hide: () => !this.hasRole("superadministrator"),
        },
        {
          text: "İkinci Kontrol",
          value: "is_fail_checked",
          searchable: "yesNo",
          align: "center",
          width: "50px",
          hide: () => !this.hasRole("superadministrator"),
        },
      ],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = [this.clusterId];
      }

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query(`/cc-payments`, {
          params,
        })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "activities.cc-payments.show",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
  },
};
</script>
